<template>
  <div class="wrap">
    <div class="header">
      <headerTop></headerTop>
    </div>
    <!-- 公司简介 -->
    <div class="company_profile" ref="company">
      <span class="title">公司简介</span>
      <i></i>
      <span class="text text_"
        >北京中科睿见科技有限公司（以下简称“中科睿见”）成立于2018年5月31日，是一家依托于中科院科技生态链的智能科技公司。<br /><br />
        中科睿见围绕三大标准化科技产品系列——业务增长系列、业务生产系列、业务基建系列，以及战略轻咨询、全域数字化运营、技术共建等定制化服务，为企业级客户提供咨询、规划和交付的全链路数字化转型解决方案，助力客户实现业务快速增长，加速数字化转型升级。<br /><br />
        中科睿见以每年200%的增长速度飞速成长，并得到了众多国有基金的大力扶植，目前已获得10家市场化基金投资，包括深创投、招商局、红土基金等国有大型资本注资。睿见科技一方面绑定国有资本、一方面拓展BAT市场化合作伙伴，依托中科院打造自有硬科技的技术孵化生态，深耕智能科技，保持稳健而快速的发展趋势。</span
      >
    </div>
    <!-- 我们的实力 -->
    <div class="strength">
      <div class="top">
        <span class="titOne">我们拥有怎样的实力呢？</span>
        <span class="titTwo">智能驱动，引领行业变革</span>
      </div>
      <ul>
        <li><img src="../assets/img/aboutUs/strength_icon1.png" alt="" /> <span>专业的研发团队</span></li>
        <li><img src="../assets/img/aboutUs/strength_icon2.png" alt="" /> <span>自主研发智能平台</span></li>
        <li><img src="../assets/img/aboutUs/strength_icon3.png" alt="" /> <span>长期服务金融行业</span></li>
      </ul>
      <div class="contentBox">
        <div class="left">
          <img src="../assets/img/aboutUs/strength_center_left.png" alt="" />
        </div>
        <div class="right">
          <span
            >公司成立以来，一直坚持自主研发和专注于核心业务的技术路线，并取得了44项自主创新型知识产权和1项发明专利。同时，我们也将中科院在计算领域的计算技术引入保险后端业务领域，实现了多种技术的融合应用。<br /><br />
            公司创始团队均来自于中科院及国际前五的高科技公司，经过三年多的发展，公司规模逐渐壮大，自主研发团队占公司员工数量70%以上，具有本科及以上学历的人工智能算法团队超过50人。为平台的运营稳定和可靠提供了专家级的技术保障。公司所有产品的算法、模型、模块均为自主研发，并且已经获得多项专利和软件著作权。</span
          >
        </div>
      </div>
      <div class="bottom">
        <span class="titOne">创新、开拓，我们从不止步</span>
        <span class="titTwo">软件著作权证书（44个）</span>
      </div>
      <!-- 证书 -->
      <div class="certificate">
        <img src="../assets/img/aboutUs/certificate.png" alt="" />
      </div>
    </div>
    <!-- 发展历程 -->
    <div class="history" ref="history">
      <h3 class="title">发展历程</h3>
      <div class="contentBox">
        <ul class="left">
          <li><span class="tit">2021.2</span><span class="text">获得深圳招商局创投、青松基金、中新嘉量A+轮融资</span></li>
          <li><span class="tit">2020.2</span><span class="text">公司全新上线微信客户端产品，致力打造智慧健康保障平台</span></li>
          <li><span class="tit">2019.3</span><span class="text">核心产品荣获八个自主创新型知识产权</span></li>
          <li><span class="tit">2018.8 </span><span class="text">公司成立</span></li>
        </ul>
        <div class="line"><span class="one"></span><span class="two"></span><span></span><span></span><span></span><span></span><span></span></div>
        <ul class="right">
          <li><span class="tit">2020.8</span><span class="text">与中再集团达成全面的产品合作</span></li>
          <li><span class="tit">2019.12</span><span class="text">获得苏州普洛斯、深创投、红土科技A轮投资</span></li>
          <li><span class="tit">2018.9</span><span class="text">获得天使轮投资</span></li>
        </ul>
      </div>
    </div>
    <!-- 企业社会价值 -->
    <div class="social_value" ref="social">
      <div class="value_top">
        <h3 class="title">企业社会价值</h3>
        <ul class="">
          <li>智能驱动</li>
          <li>科技创新</li>
          <li>跨界应用</li>
          <li>业务再造</li>
          <li>合作共赢</li>
          <li>城市守信</li>
        </ul>
      </div>
      <div class="content_box">
        <div class="item">
          <div class="left">
            <h3>为客户创造价值</h3>
            <i></i>
            <p>
              中科睿见一直致力于自有技术的研发，成功打造了七大智能产品线，包括智能理赔平台、智能核保平台、智能健康档案、医疗知识图谱、智能双录系统、智能理赔咨询机器人和智能体检报告解读。我们通过智能云平台为20多家保险公司提供了智能保险云服务，并获得了极高的月均活跃度。我们始终坚持改进产品的流程、易用性、专业性及适用范围，以满足客户的需求为服务宗旨。
            </p>
          </div>
          <div class="right">
            <img src="../assets/img/aboutUs/social_value_content1.png" alt="" />
          </div>
        </div>
        <div class="item">
          <div class="left">
            <h3>智能驱动行业变革</h3>
            <i></i>
            <p>
              睿见科技主张合作共赢，与客户、伙伴合作创新、扩大行业价值，形成健康良性的行业生态系统。推动社会可持续发展，驱动行业变革。睿见科技紧密跟踪金融方面的技术和行业发展特点，不断优化模型及算法，使客户得到最便捷优质的服务。公司长期服务于大型金融机构客户，产品上线初期即签下中国人寿、北京人寿、中国人保、中意人寿、中信银行等公司，具有良好的发展前景及发展优势。
            </p>
          </div>
          <div class="right">
            <img src="../assets/img/aboutUs/social_value_content2.png" alt="" />
          </div>
        </div>
        <div class="item">
          <div class="left">
            <h3>为努力奋斗的人提供平台</h3>
            <i></i>
            <p>睿见科技坚持以努力奋斗为根本，以责任贡献来评价员工和选拔干部，使大量年轻人有机会担当重任，快速成长，也使得员工通过个人的努力，收获了合理的回报与值得回味的人生经历。</p>
          </div>
          <div class="right">
            <img src="../assets/img/aboutUs/social_value_content3.png" alt="" />
          </div>
        </div>
      </div>
    </div>
    <pageFooter @goAnchor="goAnchor"></pageFooter>
  </div>
</template>

<script>
import headerTop from '@/components/header.vue'
import pageFooter from '@/components/footer.vue'

export default {
  name: 'aboutAs',
  components: {
    headerTop,
    pageFooter
  },
  created() {
    window.scroll(0, 0)
  },
  mounted() {
    if (this.$route.params.anchor) {
      this.$refs[this.$route.params.anchor].scrollIntoView(true)
    }
  },
  methods: {
    goAnchor(val) {
      // console.log(val)
      this.$refs[val].scrollIntoView(true)
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 60px;
  background-color: #fff;
  z-index: 999;
}
.wrap {
  padding-top: 60px;
}
// 公司简介
.company_profile {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 620px;
  background: url('../assets/img/aboutUs/company_profile_bg.png') no-repeat;
  background-size: cover;
  overflow: hidden;
  animation: slide-in-top 0.7s;
  .title {
    display: block;
    margin-top: 142px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
    text-align: center;
    animation: slide-in-top 1.3s;
  }
  i {
    display: block;
    margin: 60px auto 0;
    width: 28px;
    height: 1px;
    background-color: #788398;
    animation: slide-in-top 1.3s;
  }
  .text {
    display: block;
    margin-top: 17px;
    height: 210px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #788398;
    line-height: 42px;
    text-align: center;
    animation: slide-in-top 1.3s;
  }
  .text_ {
    text-align: left;
    width: 848px;
  }
  // 头部渐入
  @-webkit-keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
  @keyframes slide-in-top {
    0% {
      -webkit-transform: translateY(-100px);
      transform: translateY(-100px);
      opacity: 0;
    }
    100% {
      -webkit-transform: translateY(0);
      transform: translateY(0);
      opacity: 1;
    }
  }
}
// 我们的实力
.strength {
  overflow: hidden;
  .top {
    padding-left: 135px;
    .titOne {
      display: block;
      margin-top: 48px;
      height: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #212632;
      line-height: 30px;
    }
    .titTwo {
      display: block;
      margin-top: 16px;
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 28px;
    }
  }
  ul {
    margin-top: 50px;
    overflow: hidden;
    li {
      float: right;
      margin-right: 130px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 112px;
      img {
        width: 80px;
        height: 80px;
      }
      span {
        margin-top: 36px;
        height: 30px;
        font-size: 14px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #212632;
        line-height: 30px;
      }
    }
  }
  .contentBox {
    position: relative;
    height: 568px;
    .left {
      width: 498px;
      height: 568px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right {
      position: absolute;
      right: 0;
      bottom: 40px;
      width: 1151px;
      height: 370px;
      background: url('../assets/img/aboutUs/strength_center_right.png') no-repeat;
      background-size: cover;
      overflow: hidden;
      span {
        display: block;
        margin: 98px 130px;
        width: 891px;
        height: 174px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
      }
    }
  }
  .bottom {
    padding: 136px 130px 0;
    overflow: hidden;
    box-sizing: border-box;
    .titOne {
      display: block;
      height: 30px;
      font-size: 20px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #212632;
      line-height: 30px;
      text-align: right;
    }
    .titTwo {
      display: block;
      margin-top: 16px;
      margin-bottom: 126px;
      height: 28px;
      font-size: 14px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: #788398;
      line-height: 28px;
      text-align: right;
    }
  }
  .certificate {
    width: 100%;
    height: 400px;
    img {
      width: 100%;
      height: 100%;
    }
  }
}
// 发展历程
.history {
  height: 1200px;
  background: url('../assets/img/aboutUs/history_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
  box-sizing: border-box;
  .title {
    margin-top: 136px;
    height: 40px;
    font-size: 28px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 600;
    color: #010c29;
    line-height: 40px;
    text-align: center;
  }
  .contentBox {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    text-align: center;
    .left,
    .right {
      margin-top: -15px;
      width: 400px;
      text-align: right;
      li {
        margin-bottom: 173px;
        span {
          display: block;
        }
        .tit {
          margin-bottom: 8px;
          // width: 62px;
          height: 30px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #00259f;
          line-height: 30px;
        }
        .text {
          height: 30px;
          font-size: 14px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 30px;
        }
      }
    }
    .line {
      position: relative;
      display: inline-block;
      margin: 0 56px;
      height: 721px;
      width: 1px;
      background-color: rgba(0, 37, 159, 0.5);
      span {
        position: absolute;
        left: -6px;
        // display: block;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #00259f;
        &:nth-child(1) {
          top: -6px;
        }
        &:nth-child(2) {
          top: 114px;
        }
        &:nth-child(3) {
          top: 234px;
        }
        &:nth-child(4) {
          top: 354px;
        }
        &:nth-child(5) {
          top: 474px;
        }
        &:nth-child(6) {
          top: 594px;
        }
        &:nth-child(7) {
          bottom: -6px;
        }
      }
    }
    .right {
      margin-top: 105px;
      text-align: left;
    }
  }
}
// 企业社会价值
.social_value {
  overflow: hidden;
  .value_top {
    height: 300px;
    background: url('../assets/img/aboutUs/social_value_bg.png') no-repeat;
    background-size: cover;
    overflow: hidden;
    .title {
      margin-top: 82px;
      height: 40px;
      font-size: 28px;
      font-family: PingFangSC-Medium, PingFang SC;
      font-weight: 600;
      color: #ffffff;
      line-height: 40px;
      text-align: center;
    }
    ul {
      display: flex;
      justify-content: space-between;
      padding: 0 133px 0 112px;
      margin-top: 70px;
      li {
        height: 30px;
        font-size: 20px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 600;
        color: #ffffff;
        line-height: 30px;
      }
    }
  }
  .content_box {
    margin-top: 138px;
    padding: 0 130px 0 112px;
    .item {
      margin-bottom: 148px;
      overflow: hidden;
      .left {
        float: left;
        width: 600px;
        h3 {
          height: 30px;
          font-size: 20px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 600;
          color: #212632;
          line-height: 30px;
        }
        i {
          display: block;
          margin-top: 78px;
          width: 28px;
          height: 2px;
          background-color: #788398;
        }
        p {
          margin-top: 40px;
          width: 600px;
          font-size: 14px;
          font-family: PingFangSC-Regular, PingFang SC;
          font-weight: 400;
          color: #788398;
          line-height: 28px;
        }
      }
      .right {
        float: right;
        width: 494px;
        height: 270px;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &:nth-child(3) {
        margin-bottom: 136px;
      }
    }
  }
}
</style>
